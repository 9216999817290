import React from 'react'
import Icon1 from "../../images/product1-pneumatic-valve.png"
import Icon2 from "../../images/product2-valve.png";
import Icon3 from "../../images/pipeImage1.jpg"
import {ServicesContainer, ServicesH1, ServicesWrapper, 
    ServicesCard, ServicesIcon, ServicesH2, ServicesP} from "./ServiceElements"

const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1> Our Services </ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>Reduce expenses</ServicesH2>
                <ServicesP>We help reduce stuff and use valves lol</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Valves galore!</ServicesH2>
                <ServicesP>We help reduce stuff and use even more valves! lol</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3}/>
                <ServicesH2>Fluid Technoy</ServicesH2>
                <ServicesP>Our Tech is designed to work with lots of fluid and stuff</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
    
  )
}

export default Services