import React from 'react'
import logo from './logo.svg';
import Header from "./components/Header/Header"; 
import HomePage from "./components/HomePage/HomePage";
import ServiceButtons from "./components/ServiceButtons/ServiceButtons";
import Contact from "./components/Contact/Contact";
import './App.css';
import { BrowserRouter as Router, Switch, Route, Routes} from 'react-router-dom';
// import Navbar from "./components/Navbar";
// import Sidebar from "./components/Sidebar"
import Home from "./pages"
import SigninPage from './pages/signin';
// ELIAS CODE
// function App() {
//   return (
//     <div className="App">'
//       <Header />
//       <HomeButtons />
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Test Deployment
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} exact /> 
      <Route path="/signin" element={<SigninPage />} exact />
      </Routes>
    
    </Router>
    
    // <Router>
    //   <div className="App">
    //     <Header />
    //     <Routes>
    //       <Route path="/home" element={<HomePage />} /> 
    //       <Route path="/contact" element={<Contact />} />
    //     </Routes>
    //   </div>
    // </Router>
  );
}

export default App;




