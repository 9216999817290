import React from 'react'
import { SidebarContainer, 
    CloseIcon, 
    Icon, 
    SidebarWrapper, 
    SideBtnWrap, 
    SidebarMenu,
    SidebarRoute,
    SidebarLink } from "./SidebarElements";

function index({isOpen, toggle}) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>

        <SidebarWrapper>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about">
                        About
                    </SidebarLink>

                    <SidebarLink to="discover">
                        Discover</SidebarLink>

                    <SidebarLink to="services">
                        Services</SidebarLink>

                    <SidebarLink to="products">
                        Products</SidebarLink>

                    <SidebarLink to="signup">
                        Sign Up</SidebarLink>

                </SidebarMenu>

                <SideBtnWrap>
                    <SidebarRoute to="/signin"> Sign In </SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarWrapper>

    </SidebarContainer>
  )
}

export default index