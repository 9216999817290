import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { FooterContainer, FooterWrap, 
    FooterLinksContainer, FooterLinksWrapper, SocialIcons,
    FooterLinkItems, FooterLinkTitle, FooterLink,
    SocialMediaWrap, SocialIconLink, SocialLogo, 
    WebsiteRights } from './FooterElements';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };



  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>  About Us </FooterLinkTitle>
                            <FooterLink to="/signin"> How it Works</FooterLink>
                            <FooterLink to="/signin"> FAQs</FooterLink>
                            <FooterLink to="/signin"> Terms of Service</FooterLink>
                            <FooterLink to="/signin"> The Method</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>  About Us </FooterLinkTitle>
                            <FooterLink to="/signin"> How it Works</FooterLink>
                            <FooterLink to="/signin"> FAQs</FooterLink>
                            <FooterLink to="/signin"> Terms of Service</FooterLink>
                            <FooterLink to="/signin"> The Method</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>  About Us </FooterLinkTitle>
                            <FooterLink to="/signin"> How it Works</FooterLink>
                            <FooterLink to="/signin"> FAQs</FooterLink>
                            <FooterLink to="/signin"> Terms of Service</FooterLink>
                            <FooterLink to="/signin"> The Method</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>  About Us </FooterLinkTitle>
                            <FooterLink to="/signin"> How it Works</FooterLink>
                            <FooterLink to="/signin"> FAQs</FooterLink>
                            <FooterLink to="/signin"> Terms of Service</FooterLink>
                            <FooterLink to="/signin"> The Method</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMediaWrap>
                <SocialLogo to='/' onClick={toggleHome}>
                    FluidTec
                </SocialLogo>
                <WebsiteRights>FluidTec © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                <SocialIcons>
                    <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                        <FaInstagram />
                    </SocialIconLink><SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                        <FaLinkedin />
                    </SocialIconLink><SocialIconLink href="/" target="_blank" aria-label="Twitter">
                        <FaTwitter />
                    </SocialIconLink>
                </SocialIcons>
            </SocialMediaWrap>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer;