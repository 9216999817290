import React from 'react';
import './ServiceButtons.css';

function ServiceButtons() {
  return (
    <div className="service-buttons-container">
      <h2>Navigate Services</h2>
      <button className="service-button">I know my Part Number and I know which product I want</button>
      <button className="service-button">I know which product I want but I need help determining my Part Number</button>
      <button className="service-button">I need help determining which product I want</button>
      <button className="service-button">I have a different question</button>
    </div>
  );
}

export default ServiceButtons;