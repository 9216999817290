export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: false, 
    lightTextDesc: true, 
    topLine: 'Premium Bank',
    headLine: 'Unlimited Transcactions with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions with zero fees',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/product1-pneumatic-valve.png'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}