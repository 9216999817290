import React, {useState} from 'react'
import Sidebar from "../components/Sidebar"
import Navbar from "../components/Navbar"
import HeroSection from '../components/HeroSection';
import { homeObjOne } from '../components/InfoSection/Data';
import InfoSection from '../components/InfoSection/index';
import Services from '../components/Services';
import Footer from '../components/Footer';

const Home = () => {

  const [isOpen, handleIsOpen] = useState(false); 

  const toggle = ()  => {
    handleIsOpen(!isOpen);
  }


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar isOpen={isOpen} toggle={toggle}/>
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <Services/>
      <Footer />
    </>
  );
};

export default Home;