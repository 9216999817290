import React, {useState} from 'react'
import { HeroContainer, 
        HeroBg,
        VideoBg,
        MobPicBg,
        HeroH1,
        HeroContent,
        HeroP,
        HeroBtnWrapper,
        ArrowForward,
        ArrowRight, 
        Screen } from './HeroSectionElements'
import Video from "../../videos/video1.mp4"
import Image from "../../images/pipeImage2.jpg"
import { Button } from '../ButtonElement';

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

  return (
    <HeroContainer>
        <HeroBg>
            <Screen />
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            <MobPicBg src={Image} />
        </HeroBg>
        <HeroContent>
            <HeroH1> Welcome to FluidTec </HeroH1>
            <HeroP>
                We are one of the leading brands in Fluid technology or something.
                Heyyy Nuhbbbyyyyyyy Say hi to Mr Harold Happington, and I hope there 
                was no git conflicts, if you are seeing this you should download a 
                bunch of npm extensions or j text me - the number 1 bestie 
            </HeroP>
            <HeroBtnWrapper>
                <Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover}>
                    Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection