import React from 'react'

const SigninPage = () => {
  return (
    <div>
    SigninPage

    </div>
  )
}

export default SigninPage